<template>
  <card class="card-date">

    <template v-slot:header>
      <h3>{{getDay}}</h3>
    </template>
    
    <div v-for="event in getEvents" :key="event.id" class="cours">
      <div class="cours__timestamps">
        <span>{{displayDate(event.date_debut)}}</span>
        <span>{{displayDate(event.date_fin)}}</span>
      </div>
      <div class="cours__ressources">
        <span>{{event.favori.f2}}</span>
        <span>{{event.ressource}}</span>
      </div>
      <div class="cours__duration">
        <h5>{{event.duree}}</h5>
      </div>
    </div>
  </card>
</template>
<script>
import Card from "./Card.vue";
import date from "@/helpers/date"

export default {
  name: "stats-card",
  components: {
    Card,
  },
  props: {
    date: {
      type: Date,
      required: true,
    },
    months: {
      type: Object,
      required: true
    }
  },
  computed: {
    getStart() {
      return new Date(this.date.setHours(0, 0, 0, 0));
    },
    getEnd() {
      return new Date(this.date.setHours(23, 59, 59, 999));
    },
    getEventsInMonth() {
      return this.months[`${this.date.getFullYear()}-${this.date.getMonth()}`] || [];
    },
    getEvents() {
      return this.getEventsInMonth.filter(event => new Date(event.date_debut) >= this.getStart &&  new Date(event.date_debut) <= this.getEnd);
    },
    getDay() {
      return this.date.toLocaleDateString(this.$i18n.locale, { weekday: 'long' });
    }
  },
  methods: {
    displayDate(timestamp) {
      const date = new Date(timestamp); 
      return `${this.format(date.getHours())}:${this.format(date.getMinutes())}`;
    },
    format(n) {
      return n > 9 ? "" + n: "0" + n;
    }
  }
};
</script>
<style>
</style>
