<template>
  <card class="card-calendar">
    <!-- <v-calendar
      is-expanded
      trim-weeks
      :attributes="attributes"
      is-dark
      is-range
      ref="calendar"
    /> -->

    <template v-slot:header>
      <base-button round icon type="primary" @click="previousWeek">
        <i class="tim-icons icon-minimal-left"></i>
      </base-button>
      <h4>{{title}}</h4>
      <base-button round icon type="primary" @click="nextWeek">
        <i class="tim-icons icon-minimal-right"></i>
      </base-button>
    </template>

    <template>
      <DateCard
        class="card-date"
        v-for="(date, index) in currentWeekToDisplay"
        :key="index"
        :date="date"
        :months="months"
      />
    </template>

    <div class="calendar-actions">
      <base-button round icon type="primary" size="lg" v-if="!isTodayInCurrentWeek" @click="setTodaysWeek">
        <i class="tim-icons icon-calendar-60"></i>
      </base-button>
    </div>
  </card>
</template>
<script>
import DateCard from "@/components/Cards/DateCard";
import config from "@/config";
import calendarService from "@/services/calendar";
import date from "@/helpers/date";

export default {
  components: {
    DateCard,
  },
  data() {
    return {
      months: {},
      daysToDisplay: 5,
      currentWeek: [],
      today: new Date()
    };
  },
  computed: {
    attributes() {
      return this.months.map((date) => ({
        highlight: date.matiere === "Pédagogique" ? "red" : "blue",
        months: date,
      }));
    },
    daysInCurrent() {
      return `${date.getDay(this.currentWeekToDisplay[0])}/${date.getDay(this.currentWeekToDisplay[this.currentWeekToDisplay.length-1])}`
    },
    title() {
      return `${this.daysInCurrent} ${this.currentWeek[0].toLocaleString('default', { month: 'long' })} ${this.currentWeek[0].getFullYear()}`
    },
    currentWeekToDisplay() {
      return this.currentWeek.slice(0, this.daysToDisplay);
    },
    firstDay() {
      return this.currentWeek[0];
    },
    lastDay() {
      return this.currentWeek[this.currentWeek.length - 1];
    },
    isTodayInCurrentWeek() {
      return this.today.getDate() >= this.firstDay.getDate() && this.today.getDate() <= this.lastDay.getDate();
    }
  },
  methods: {
    async nextWeek() {
      this.currentWeek = date.getNextWeek(this.firstDay);
      this.updateNewMonth();
    },
    async previousWeek() {
      this.currentWeek = date.getPreviousWeek(this.firstDay);
      this.updateNewMonth();
    },
    async updateNewMonth() {
      this.$set(this.months, `${this.firstDay.getFullYear()}-${this.firstDay.getMonth()}`, await calendarService.getEventsByMonth(this.firstDay));
      this.$set(this.months, `${this.lastDay.getFullYear()}-${this.lastDay.getMonth()}`, await calendarService.getEventsByMonth(this.lastDay));
    },
    setTodaysWeek() {
      this.currentWeek = date.getDaysInCurrentWeek(new Date());
      this.updateNewMonth();
    }
  },
  async created() {
    this.setTodaysWeek();
  },
};
</script>
<style lang="scss">
.calendar-actions {
  position: fixed;
  bottom: 20px;
  right: 20px;  
}
</style>
